import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderBlock } from 'shared/ui/headerBlock';
import { PopularTags } from 'shared/ui/popularTags';
import { NewAccordion } from 'shared/ui/newAccordion';
import { ActionPanel } from 'shared/ui/actionPanel';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { NativeActions } from 'shared/model';
import { setActionForNative } from 'shared/lib';
import { generateNativeLink } from 'shared/native/generateLink/generateNativeLink';
import { ActionPanelItem, PanelItemType } from 'shared/ui/actionPanel/model/actionPanelItem';
import { useFetchCurrentMainPageQuery } from 'entities/main/api/mainLayoutApi';
import { Stories } from 'widgets/stories';
import { ServiceCatalogue } from 'widgets/serviceCatalogue';
import { PersonalServicesList } from 'widgets/personalServicesList';
import UltraPromoBlock from './ui/promoBlock/UltraPromoBlock';
import AdFoxUltra from './ui/adfox/adFoxUltra';

export default function UltraPage() {
  const { t } = useTranslation();

  const { data, isLoading } = useFetchCurrentMainPageQuery({ tab: MainPageTabType.Ultra }, { pollingInterval: 60000 });

  const handleOpenGidClick = () => setActionForNative(NativeActions.OPEN_GID);
  const [openStories, setOpenStories] = React.useState(false);

  const handleOnActionCardClick = (item: ActionPanelItem) => {
    if (item.type === PanelItemType.Stories) {
      setActionForNative(NativeActions.SHOW_NAVBAR, { show: false });
      setOpenStories(true);
    } else {
      generateNativeLink(item.type, item?.data);
    }
  };

  const handleCloseStories = () => {
    setActionForNative(NativeActions.SHOW_NAVBAR, { show: true });
    setOpenStories(false);
  };

  return (
    <>
      {data && !data?.ad_disabled && <AdFoxUltra />}

      <div>
        <ActionPanel
          items={data?.action_panel}
          isLoading={isLoading}
          onActionCardClick={(item) => handleOnActionCardClick(item)}
        />
      </div>

      {!!data?.interest_suggest.length && (
        <div>
          <HeaderBlock title={t('page.main.interest_suggest_ultra')} />
          <PersonalServicesList popularServices={data?.interest_suggest} type={MainPageTabType.Ultra} isWork />
        </div>
      )}

      {!!data?.recent_views.length && (
        <div style={{ gridGap: 0 }}>
          <HeaderBlock title={t('page.main.recent_views')} style={{ marginBottom: '.4rem' }} />
          <PopularTags tags={data?.recent_views} type={MainPageTabType.Ultra} />
        </div>
      )}

      <div>
        <HeaderBlock title={t('page.main.categories_list_ultra')} isLoading={isLoading} />
        <ServiceCatalogue services={data?.categories} isLoading={isLoading} type={MainPageTabType.Ultra} />
      </div>

      {data?.promoblock?.is_show && (
        <div>
          <UltraPromoBlock />
        </div>
      )}

      <div>
        <HeaderBlock title={t('page.main.faq')} isLoading={isLoading}>
          <HeaderBlock.Button text={t('page.main.faq_show_more')} onClick={handleOpenGidClick} />
        </HeaderBlock>

        <NewAccordion faq={data?.questions} isLoading={isLoading} />
      </div>

      {openStories && (
        <Stories
          onClose={handleCloseStories}
          items={
            data?.action_panel[0].stories ? data?.action_panel[0].stories[0].stories.map((item) => item.image) : []
          }
        />
      )}
    </>
  );
}
