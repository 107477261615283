import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'shared/v12ui';
import { useAppDispatch } from 'shared/model';
import { setPathFromMain } from 'shared/model/slices/generals';
import styles from './UltraPromoBlock.module.scss';

export default function UltraPromoBlock() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOpenCreateOrder = () => {
    dispatch(setPathFromMain(location.pathname));
    navigate('/order');
  };

  return (
    <div className={styles.promo_block}>
      <div className={styles.text_block}>
        <span className={styles.title}>{t('page.main.promo_block.ultra.title')}</span>
        <span className={styles.description}>{t('page.main.promo_block.ultra.description')}</span>
      </div>

      <div>
        <Button text={t('page.main.promo_block.ultra.button')} isRounded onClick={handleOpenCreateOrder} />
      </div>
    </div>
  );
}
