import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useScrollRestoration } from 'use-scroll-restoration';
import { Input } from 'shared/v12ui';
import { NavigationTabs } from 'shared/ui/navigationTabs';
import {
  addNotification,
  NativeActions,
  selectIsDemo,
  updateSearchUrl,
  useAppDispatch,
  useAppSelector,
} from 'shared/model';
import { Toast } from 'shared/ui';
import { selectLoginNotificationIsReceived, setLoginNotificationIsReceived } from 'shared/model/slices/generals';
import { useFetchMainTabsQuery } from './api/mainLayoutApi';
import { setActionForNative, useSetHeaders } from 'shared/lib';
import mainStyle from 'shared/styles/mainWithHeader.module.scss';
import styles from './MainPageLayout.module.scss';

export default function MainPageLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pageWrapperRef = React.useRef<HTMLElement | null>(null);
  const demoLoginNotifcation = useAppSelector(selectLoginNotificationIsReceived);
  const isDemoClient = useAppSelector(selectIsDemo);
  const { isSuccess } = useSetHeaders();

  const { ref } = useScrollRestoration('pageLayout', {
    debounceTime: 200,
    persist: 'sessionStorage',
  });

  const { data: mainTabs, isLoading } = useFetchMainTabsQuery(null, { skip: !isSuccess });

  const onSearchClick = () => {
    navigate('/search/');
    dispatch(updateSearchUrl(location.pathname));
    setActionForNative(NativeActions.SHOW_NAVBAR, { show: false });
  };

  const getWrapperRef = (element: HTMLDivElement | null) => {
    ref(element);
    pageWrapperRef.current = element;
  };

  React.useLayoutEffect(() => {
    if (pageWrapperRef?.current) {
      // рестор скрола при переключение между табами
      pageWrapperRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (!isDemoClient) return;
    if (demoLoginNotifcation) return;
    dispatch(setLoginNotificationIsReceived(true));
    dispatch(addNotification({ text: t('page.main.demo_login_request'), icon: 'bell', type: 'demoLogin' }));
  }, [demoLoginNotifcation, isDemoClient]);

  React.useEffect(() => {
    setActionForNative(NativeActions.SHOW_NAVBAR, { show: true });
    return () => {
      setActionForNative(NativeActions.SHOW_NAVBAR, { show: false });
    };
  }, []);

  if (!isSuccess) return null;

  return (
    <div ref={getWrapperRef} className={mainStyle.main_wrapper}>
      <div className={mainStyle.main_header}>
        <div className={mainStyle.search_holder}>
          <Input
            placeholder={t('common.search')}
            icon={'search'}
            hasSpace={false}
            disabled={isLoading}
            readOnly
            onClick={onSearchClick}
          />
        </div>
        <NavigationTabs tabs={mainTabs?.tabs} isLoading={isLoading} />
      </div>

      <div className={styles.page_wrapper}>
        <Outlet />
      </div>

      <Toast bottomPosition={8} />
    </div>
  );
}
