import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'shared/model';

interface NotificationItem {
  icon?: string;
  text: string;
  type?: 'remove' | 'demoLogin' | 'default';
  id?: string;
}

const initialState: {
  notifications: Nullable<NotificationItem>;
  galleryOpen: boolean;
  currentCity: Nullable<{ id: number; name: string }>;
  searchUrl: string;
  pathFromMain: Nullable<string>;
  loginNotificationIsReceived?: boolean;
} = {
  notifications: null,
  galleryOpen: false,
  currentCity: null,
  searchUrl: '/',
  pathFromMain: null,
  loginNotificationIsReceived: false,
};

const slice = createSlice({
  name: 'generals',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Nullable<NotificationItem>>) {
      state.notifications = action.payload;
    },
    setGalleryOpen(state, action: PayloadAction<boolean>) {
      state.galleryOpen = action.payload;
    },
    removeNotification(state) {
      state.notifications = null;
    },
    setCurrentCity(state, action: PayloadAction<Nullable<{ id: number; name: string }>>) {
      state.currentCity = action.payload;
    },
    updateSearchUrl(state, action: PayloadAction<string>) {
      state.searchUrl = action.payload;
    },
    setPathFromMain(state, action: PayloadAction<Nullable<string>>) {
      state.pathFromMain = action.payload;
    },
    setLoginNotificationIsReceived(state, action: PayloadAction<boolean>) {
      state.loginNotificationIsReceived = action.payload;
    },
  },
});

export const {
  addNotification,
  removeNotification,
  setGalleryOpen,
  setCurrentCity,
  updateSearchUrl,
  setPathFromMain,
  setLoginNotificationIsReceived,
} = slice.actions;
export default slice.reducer;

export const selectNotificationList = (state: { generals: typeof initialState }) => state.generals.notifications;
export const selectGalleryOpen = (state: { generals: typeof initialState }) => state.generals.galleryOpen;
export const selectCurrentCity = (state: { generals: typeof initialState }) => state.generals.currentCity;
export const selectSearchUrl = (state: { generals: typeof initialState }) => state.generals.searchUrl;
export const selectPathFromMain = (state: { generals: typeof initialState }) => state.generals.pathFromMain;
export const selectLoginNotificationIsReceived = (state: { generals: typeof initialState }) =>
  state.generals.loginNotificationIsReceived;
