import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '../../v12ui';
import { NavigationTabItem } from './model/navigationTabItem';
import { SkeletonLayout } from '../../../entities/skeleton';
import { setActionForNative } from 'shared/lib';
import { NativeActions } from 'shared/model';
import cn from 'classnames';
import styles from './NavigationTabs.module.scss';

interface Props {
  tabs?: NavigationTabItem[];
  isLoading: boolean;
  withoutIcon?: boolean;
}

function NavigationTabs({ tabs, isLoading, withoutIcon = false }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const listRef = React.useRef<HTMLUListElement>(null);
  const listItemsRefs = React.useRef<(HTMLLIElement | null)[]>([]);

  const [scrollLineWidth, setScrollLineWidth] = React.useState(0);
  const [activeLineStyle, setActiveLineStyle] = React.useState<{
    width: number;
    left: number;
    color?: 'default' | 'red';
  }>({
    width: 0,
    left: 0,
    color: 'default',
  });

  const updateActiveLine = () => {
    if (isLoading) return;
    const activeItem = listItemsRefs.current.find((el) => el?.classList.contains(styles.is_active));
    const isPrimeActiveItem = listItemsRefs.current.find((el) => el?.classList.contains(styles.is_active_ultra));

    if (activeItem && listRef.current) {
      const { width, left } = activeItem.getBoundingClientRect();
      const containerLeft = listRef.current.getBoundingClientRect().left;
      const color = !isPrimeActiveItem ? 'default' : 'red';
      setActiveLineStyle({ width, left: left - containerLeft + listRef.current.scrollLeft, color });
    }
  };

  const handleOnTabClick = (index: number, url: string) => {
    const item = listItemsRefs.current[index];

    if (item) {
      item.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      updateActiveLine();
    }

    if (url.includes('http')) {
      setActionForNative(NativeActions.OPEN_IN_APP_BROWSER, { url });
    } else {
      navigate(`${url}`, { relative: 'path' });
    }
  };

  React.useLayoutEffect(() => {
    if (listItemsRefs.current) {
      const width = listItemsRefs.current.reduce((acc, item, index, array) => {
        const additionalWidth = index < array.length - 1 ? 8 : 0;
        return acc + (item?.offsetWidth || 0) + additionalWidth;
      }, 0);
      setScrollLineWidth(width < window.innerWidth ? window.innerWidth : width + 32);
    }
  }, [listItemsRefs, tabs]);

  React.useEffect(() => {
    updateActiveLine();
  }, [tabs, location.pathname]);

  return (
    <ul ref={listRef} className={styles.navigation_wrapper}>
      {isLoading
        ? Array.from({ length: 6 }).map((_, index) => {
            return (
              <li key={index} className={styles.skeleton_item}>
                {!withoutIcon && <SkeletonLayout width={20} height={20} borderRadius={4} theme={'dark'} />}
                <SkeletonLayout
                  width={72}
                  height={16}
                  borderRadius={6}
                  theme={'dark'}
                  containerClass={!withoutIcon ? 'mgt-2' : ''}
                />
              </li>
            );
          })
        : tabs?.map((item, index) => {
            return (
              <li
                onClick={() => {
                  handleOnTabClick(index, item.url);
                }}
                key={index}
                ref={(element) => {
                  if (element) {
                    listItemsRefs.current[index] = element;
                  }
                }}
                className={cn(
                  styles.navigation_item,
                  { [styles.is_active]: location.pathname === item.url },
                  { [styles.is_active_ultra]: location.pathname.includes('ultra') && item.icon === 'prime' },
                )}
              >
                <Icon iconColor={item.icon === 'prime' ? '#BF1005' : ''}>{item.icon}</Icon>
                <span className={cn(styles.item_text, item.icon ? 'mgt-2' : '')}>{item.title}</span>
              </li>
            );
          })}
      <div className={styles.scroll_line} style={{ width: scrollLineWidth }} />

      {tabs && activeLineStyle.left >= 16 && (
        <div
          className={styles.active_scroll_line}
          style={{
            width: activeLineStyle.width,
            transform: `translateX(${activeLineStyle.left - 16}px)`,
            backgroundColor: activeLineStyle.color === 'red' ? '#BF1005' : undefined,
          }}
        />
      )}
    </ul>
  );
}

export default React.memo(NavigationTabs);
