import { useTranslation } from 'react-i18next';
import { HeaderBlock } from 'shared/ui/headerBlock';
import { PopularTags } from 'shared/ui/popularTags';
import { NewAccordion } from 'shared/ui/newAccordion';
import { ActionPanel } from 'shared/ui/actionPanel';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { useFetchCurrentMainPageQuery } from 'entities/main/api/mainLayoutApi';
import { ServiceCatalogue } from 'widgets/serviceCatalogue';
import { PersonalServicesList } from 'widgets/personalServicesList';
import AdFoxVacancy from './ui/adfox/adFoxVacancy';
import { setActionForNative } from 'shared/lib';
import { NativeActions } from 'shared/model';
import { ActionPanelItem } from 'shared/ui/actionPanel/model/actionPanelItem';
import { generateNativeLink } from 'shared/native/generateLink/generateNativeLink';

export default function VacancyPage() {
  const { t } = useTranslation();

  const { data, isLoading } = useFetchCurrentMainPageQuery(
    { tab: MainPageTabType.Vacancy },
    { pollingInterval: 60000 },
  );

  const handleOpenGidClick = () => setActionForNative(NativeActions.OPEN_GID);

  const handleOnActionCardClick = (item: ActionPanelItem) => generateNativeLink(item.type, item?.data);

  return (
    <>
      {data && !data?.ad_disabled && <AdFoxVacancy />}

      <div>
        <ActionPanel
          items={data?.action_panel}
          isLoading={isLoading}
          onActionCardClick={(item) => handleOnActionCardClick(item)}
        />
      </div>

      {data?.interest_suggest && (
        <div>
          <HeaderBlock title={t('page.main.interest_suggest')} />
          <PersonalServicesList popularServices={data?.interest_suggest} type={MainPageTabType.Vacancy} isWork />
        </div>
      )}

      {!!data?.recent_views.length && (
        <div style={{ gridGap: 0 }}>
          <HeaderBlock title={t('page.main.recent_views')} style={{ marginBottom: '.4rem' }} />
          <PopularTags tags={data?.recent_views} type={MainPageTabType.Vacancy} />
        </div>
      )}

      <div>
        <HeaderBlock title={t('page.main.categories_list')} isLoading={isLoading} />
        <ServiceCatalogue services={data?.categories} isLoading={isLoading} type={MainPageTabType.Vacancy} />
      </div>

      <div>
        <HeaderBlock title={t('page.main.faq')} isLoading={isLoading}>
          <HeaderBlock.Button text={t('page.main.faq_show_more')} onClick={handleOpenGidClick} />
        </HeaderBlock>

        <NewAccordion faq={data?.questions} isLoading={isLoading} />
      </div>
    </>
  );
}
