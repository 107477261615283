import { NavigateFunction } from 'react-router-dom';
import { setActionForNative } from 'shared/lib';
import { NativeActions } from 'shared/model';
import { PanelItemType } from 'shared/ui/actionPanel/model/actionPanelItem';

export interface LinkData {
  from?: string;
  purchase_data?: {
    ticket_id?: number;
  };
  ticket_id?: number;
  work_id?: number;
  service_id?: number;
  specialist_id?: number;
}

export function generateNativeLink(type: PanelItemType, data?: LinkData, navigate?: NavigateFunction) {
  const params = new URLSearchParams();

  if (data?.service_id) {
    params.append('service_id', `${data.service_id}`);
  }
  if (data?.work_id) {
    params.append('work_id', `${data.work_id}`);
  }
  if (data?.ticket_id) {
    params.append('ticket_id', `${data.ticket_id}`);
  }
  if (data?.specialist_id) {
    params.append('specialist_id', `${data.specialist_id}`);
  }

  switch (type) {
    case PanelItemType.Pay_Guarantee:
      if (!data?.from || !data?.purchase_data?.ticket_id) return null;
      return setActionForNative(NativeActions.OPEN_LINK, {
        link: `naimi://guarantee/payments?from=${data?.from}&ticket_id=${data?.purchase_data.ticket_id}`,
      });
    case PanelItemType.Vacancy:
    case PanelItemType.Order:
      if (!data?.ticket_id) return null;
      return setActionForNative(NativeActions.OPEN_LINK, { link: `naimi://ticket/bids?ticket_id=${data?.ticket_id}` });
    case PanelItemType.Create_Vacancy:
      return setActionForNative(NativeActions.OPEN_LINK, { link: `naimi://vacancy/create?${params.toString()}` });
    case PanelItemType.Create_Guarantee:
      if (!data?.service_id && !data?.work_id) return null;
      return setActionForNative(NativeActions.OPEN_LINK, {
        link: `naimi://guarantee/create?${params.toString()}`,
      });
    case PanelItemType.Create_Order:
      return navigate && navigate(`/order?${params.toString()}`);
    case PanelItemType.Guarantee:
      return setActionForNative(NativeActions.OPEN_LINK, { link: `naimi://guarantee/ticket?${params.toString()}` });
    case PanelItemType.SpecialistsList:
      return setActionForNative(NativeActions.OPEN_LINK, { link: `naimi://catalogue?${params.toString()}` });
    case PanelItemType.Bids:
      return setActionForNative(NativeActions.OPEN_LINK, { link: `naimi://chat?mode=client&${params.toString()}` });
  }
}
