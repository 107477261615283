import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { RootRoutes } from '../routes';
import { changeLanguage } from 'i18next';
import { ErrorNotification } from 'components/ErrorNotification';
import { useAppDispatch, setLocale, NativeActions } from 'shared/model';
import { PhotoSwipeProvider } from 'shared/ui';
import { NoInternetConnection } from 'entities/general';
import ErrorBoundary from 'shared/config/ErrorBoundary';
import { setActionForNative } from 'shared/lib';
import styles from './App.module.scss';

function App() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const localeValue = searchParams.get('locale');

  React.useEffect(() => {
    if (localeValue) {
      changeLanguage(localeValue);
      dispatch(setLocale(localeValue));
    }
  }, [dispatch, localeValue]);

  React.useLayoutEffect(() => {
    window.fromNative = (module, action, data) => {
      //@ts-ignore
      window.lastNativeMessage = { module, action, data };
      window.dispatchEvent(new CustomEvent('nativeMessage', { detail: { module, action, data } }));
    };
  }, []);

  const handleOnClick = () => setActionForNative(NativeActions.TERMINATE);

  return (
    <div className={styles.wrapper}>
      <NoInternetConnection>
        <PhotoSwipeProvider>
          <ErrorBoundary onClick={handleOnClick}>
            <RootRoutes />
          </ErrorBoundary>
        </PhotoSwipeProvider>
      </NoInternetConnection>
      <ErrorNotification />
    </div>
  );
}

export default App;
